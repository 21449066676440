<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import Vue, { computed, reactive } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import { cardCode } from '/~/utils/cards'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerMethodTile from '/~/components/drawer/components/tiles/drawer-method-tile.vue'
import { useCms } from '/~/composables/cms/use-cms'
import {
  getExpiryDate,
  usePaymentMethods,
  PaymentMethodType,
} from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'drawer-card-tile-v2',
  components: {
    BaseIcon,
    BaseLoader,
    DrawerMethodTile,
  },
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: Boolean,
      default: true,
    },
    inForm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { deleting, deletePaymentMethod, initiatingAccountsIds } =
      usePaymentMethods()
    const { isDarkThemeForEwallet } = useCms()
    const { isCreditCardsNavEnabled } = useProvider()

    const cardNumberEnding = computed(() => props.card.number.slice(-4))

    const expiryDate = reactive(getExpiryDate(props.card))

    function onRemoveClick() {
      bottomSheet.show('confirmation-modal', {
        to: 'menu-modal',
        props: {
          icon: 'points-only/trash',
          title: 'Are you sure you want to delete this Payment Method?',
          text: `By deleting this Payment Method you will remove the card ending in ${cardNumberEnding.value} from your Payment Methods permanently.`,
          confirmButtonText: 'Delete Payment Method',
          onConfirm: onDeleteConfirmationModalConfirm,
        },
      })
    }

    async function onDeleteConfirmationModalConfirm() {
      try {
        await deletePaymentMethod(props.card.id)
      } catch (error) {
        if (!error.data?.message) {
          Vue.notify({
            type: 'error',
            text: 'Something went wrong while deleting. Please try again.',
          })
        }
      }
    }

    return {
      isDarkThemeForEwallet,
      deleting,
      deletePaymentMethod,
      onRemoveClick,
      initiatingAccountsIds,
      isCreditCardsNavEnabled,
      expiryDate,
      PaymentMethodType,
    }
  },
  computed: {
    gradient() {
      const gradients = {
        visa: 'linear-gradient(270deg, #0b2c59 0%, #2770b6 100%)',
        mastercard: 'linear-gradient(270deg, #131313 0%, #797979 100%)',
        amex: 'linear-gradient(270deg, #44629a 0%, #6093c9 100%)',
      }

      return (
        gradients[this.code] ||
        'linear-gradient(241.41deg, #9D9D9D 0%, #C4C4C4 100%)'
      )
    },
    initializing() {
      return this.initiatingAccountsIds.includes(this.card.id)
    },
    month() {
      const expMonth = this.card?.expiry?.month ?? ''

      return `0${expMonth}`.slice(-2)
    },
    year() {
      const expYear = this.card?.expiry?.year ?? ''

      return `${expYear}`.slice(-2)
    },
    code() {
      return cardCode(this.card)
    },
    rawNumber() {
      return this.card.number.replace(/\s/g, '')
    },
    number() {
      return (this.card?.number ?? '').slice(-4)
    },
    status() {
      return (this.card?.status ?? '').toLowerCase()
    },
    isVerified() {
      return this.status === 'verified' || !this.isCreditCardsNavEnabled
    },
    isBlocked() {
      return this.status === 'blocked' || this.status === 'locked'
    },
    expectedLength() {
      switch (this.code) {
        case 'amex':
          return 15
        default:
          return 16
      }
    },
    presentationalDots() {
      const givenLength = this.rawNumber.length
      const neededDotsCount = this.expectedLength - givenLength

      if (neededDotsCount > 0) {
        const dots = new Array(neededDotsCount)
          .fill('*')
          .join('')
          .match(/.{0,4}/g)
          .reverse()
          .join(' ')
          .trim()

        return dots
      }

      return ''
    },
    presentationalMonth() {
      const expMonth = this.card?.expiry?.month ?? ''

      switch (expMonth.length) {
        case 1:
          return `${expMonth.slice(-1)}M`
        case 2:
          return expMonth.slice(-2)
        default:
          return 'MM'
      }
    },
    presentationalYear() {
      const expYear = this.card?.expiry?.year ?? ''

      switch (expYear.length) {
        case 3:
          return `${expYear.slice(-1)}Y`
        case 4:
          return expYear.slice(-2)
        default:
          return 'YY'
      }
    },
    cardInfo() {
      return getCardTypeByNumber(this.card.number)
    },
    presentation() {
      return {
        type: this.PaymentMethodType.creditCard,
        brand: this.cardInfo?.type,
        number: this.card.number,
        name: this.card.name,
        expiry: {
          month: ('0' + this.card.expiry.month.toString()).slice(-2),
          year: this.card.expiry.year.toString(),
        },
      }
    },
  },
  methods: {
    verify() {
      if (!this.isVerified && !this.isBlocked && !this.initializing) {
        bottomSheet.show('method-verify', {
          to: 'menu-modal',
          props: {
            method: { ...this.card, type: this.PaymentMethodType.creditCard },
          },
        })
      }
    },
  },
}
</script>

<template>
  <div class="max-w-72 relative mx-auto">
    <drawer-method-tile
      v-analytics:click="{
        pageGroup: 'Drawer',
        page: 'Payment Methods',
        component1: 'Credit card',
        cta: 'Verify Account',
      }"
      :method="presentation"
      :class="[
        !isVerified && !inForm && !initializing
          ? 'cursor-pointer'
          : 'cursor-default',
      ]"
      @click="verify"
    />

    <div
      class="absolute right-0 top-0 -mr-2.5 -mt-2.5 flex items-center gap-x-[5px]"
    >
      <div
        v-if="expiryDate.isExpired"
        class="flex items-center rounded-full bg-amber-500 px-2.5 py-[5px] text-white"
      >
        <base-icon svg="v2/heroic/exclamation" :size="24" class="mr-[5px]" />
        <span class="whitespace-no-wrap font-bold uppercase">Expired</span>
      </div>
      <button
        v-else-if="!isVerified"
        type="button"
        class="flex cursor-pointer items-center rounded-full px-2.5 py-[5px] text-white"
        :style="{
          backgroundColor: '#F59E0B',
        }"
        @click="verify"
      >
        <base-loader v-if="initializing" size="sm" />
        <template v-else>
          <base-icon svg="v2/heroic/exclamation" :size="24" class="mr-[5px]" />
          <span class="whitespace-no-wrap font-bold uppercase">
            Verify account
          </span>
        </template>
      </button>
      <button
        type="button"
        class="flex h-10 w-10 items-center justify-center rounded-full border-4 bg-red-700 text-white disabled:bg-gray-300"
        :class="{
          'border-white': !isDarkThemeForEwallet,
        }"
        :disabled="deleting"
        :aria-label="`Card number ${card.number} remove`"
        @click="onRemoveClick"
      >
        <base-loader v-if="deleting" size="sm" />
        <base-icon v-else svg="v2/heroic/trash" alt="remove" :size="16" />
      </button>
    </div>
  </div>
</template>
